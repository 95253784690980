.is-on-top {
  z-index: 100;
}

.flex-should-wrap {
  flex-wrap: wrap;
}

.is-inline-flex {
  display: inline-flex;
}

.is-flex-column {
  flex-direction: column;
}

.is-concise-message {
  padding: 0.7em;
  display: block;
  width: 150px;
}

.is-flex-centered {
  justify-content: center;
}

.is-vcentered {
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
}

.is-full-width {
  width: 100%;
}

.m-r-sm {
  margin-right: 0.5rem;
}

.no-breaks {
  white-space: nowrap;
}

.min-content {
  min-width: min-content !important;
}

.space-evenly {
  justify-content: space-evenly;
}
.video {
  transform: rotateY(180deg);
}